body {
  font-family: "Pretendard";
}

.writePage {
  display: flex;
  margin:0;
  flex-direction: column;
  align-items: center;
  // height: 800px;
  background-color: rgb(0, 0, 0);
}

.writePage .colBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10px;
  background-color: rgb(0, 0, 0);
}

.writePage .content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  // height: 1250px;
  width: 1320px;
  background-color: rgb(128, 128, 128);
}

.writePage .content1 .window1{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 150px;
  width: 1120px;
  // background-color: rgb(255, 255, 255);
}

.writePage .content1 .window1 .comm{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  height: 45px;
  width: 1120px;
  color:rgb(0, 0, 0);
  font-size: 25px;
  font-weight: bold;
  background-color:rgb(128, 128, 128);
}
.writePage .content1 .window1 .windowHead{
  display: flex;
  // flex-direction: row;
  align-items: center;
  // justify-content: center;
  // align-items: center;
  height: 45px;
  width: 1120px;
  color:rgb(255, 255, 255);
  font-size: 20px;
  background-color: rgb(0, 0, 0);
}

.writePage .content1 .window1 .windowHead .titlehead{
  display: flex;
  // flex-direction: column;
  margin-left: 10px;
  align-items: left;
  // justify-content: center;
  height: 45px;
  width: 150px;
  color:rgb(255, 255, 255);
  font-size: 25px;
  background-color: rgb(0, 0, 0);
}

.writePage .content1 .todo-content{
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: center;
  // align-items: center;
  height: 745px;
  width: 1120px;
  color:rgb(0, 0, 0);
  font-size: 20px;
  background-color: rgb(255, 255, 255);
}

.writePage .content1 .todo-content .table{
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // align-items: center;
  // height: 745px;
  width: 1120px;
  color:rgb(0, 0, 0);
  font-size: 20px;
  background-color: rgb(255, 255, 255);
}

.writePage .content1 .todo-content .table .tableTitle{
  display: flex;
  width: 1120px;
  color:rgb(255, 0, 0);
  padding-top: 10px;
  padding-left: 50px;
  font-size: 20px;
  // background-color: rgb(255, 255, 255);
}

.writePage .content1 .todo-content .table .tableTitle .table-rec{
  display: flex;
  width: 150px;
}
.writePage .content1 .todo-content .table .tableTitle .table-cnt{
  display: flex;
  width: 150px;
}
.writePage .content1 .todo-content .table .tableTitle .table-aut{
  display: flex;
  width: 150px;
}
.writePage .content1 .todo-content .table .tableTitle .table-tit{
  display: flex;
  width: 150px;
}


.writePage .content1 .todo-content .table .tableData{
  display: flex;
  width: 1120px;
  color:rgb(0, 0, 0);
  padding-top: 10px;
  padding-left: 50px;
  font-size: 20px;
  // background-color: rgb(255, 255, 255);
}

.writePage .content1 .todo-content .table .tableData .table-rec{
  display: flex;
  width: 150px;
}
.writePage .content1 .todo-content .table .tableData .table-cnt{
  display: flex;
  width: 150px;
}
.writePage .content1 .todo-content .table .tableData .table-aut{
  display: flex;
  width: 150px;
}
.writePage .content1 .todo-content .table .tableData .table-tit{
  display: flex;
  width: 150px;
}

.writePage .content1 .page{
  display: flex;
  height: 100px;
  width: 1150px;
}