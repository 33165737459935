body {
  font-family: "Pretendard";
}

.writePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 800px;
  background-color: rgb(0, 0, 0);
}

.writePage .colBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10px;
  background-color: rgb(0, 0, 0);
}

.writePage .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800px;
  width: 1320px;
  background-color: rgb(128, 128, 128);
}

.writePage .content .window{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 700px;
  width: 1120px;
  background-color: rgb(255, 255, 255);
}

.writePage .content .window .windowHead{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // align-items: center;
  height: 45px;
  width: 1120px;
  color:rgb(255, 255, 255);
  font-size: 25px;
  background-color: rgb(0, 0, 0);
}

.writePage .content .window .windowHead .titlehead{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: left;
  justify-content: center;
  height: 45px;
  width: 1120px;
  color:rgb(255, 255, 255);
  font-size: 24px;
  background-color: rgb(0, 0, 0);
}

.writePage .content .window .windowHead .enrollbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right:5px;
  height: 35px;
  width: 100px;
  color:rgb(0, 0, 0);
  font-size: 25px;

  background-color: rgb(255, 115, 34);
  border: none;
  border-radius: 4px;
}

.writePage .content .window .headbar{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1120px;
  height: 5px;
  background-color: rgb(255, 115, 34);
}

.writePage .content .window .board{
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-top: 20px;
  margin-left: 50px;
  width: 1120px;
  // height: 50px;
  // background-color: rgb(255, 115, 34);
}
.writePage .content .window .board .boardtitle{
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 125px;
  font-size: 15pt;
  // height: 50px;
}
.writePage .content .window .board .boardSetting{
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 125px;
  height: 25px;
}

.writePage .content .window .title{
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-top: 10px;
  margin-left: 50px;
  width: 1120px;
}
.writePage .content .window .title .titleUser{
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 125px;
  font-size: 15pt;
  // height: 50px;
}
.writePage .content .window .title .titleWrite{
  display: flex;
  flex-direction: row;
  align-items: left;
  width: 920px;
  height: 25px;
}

.writePage .content .window .textbody{
  vertical-align: top;
  margin-top: 15px;
  // display:flex;
  // flex-direction: column;
  // // align-items: center;
  width: 1070px;
  height: 480px;
  font-size: 15px;
  background-color: rgb(255, 255, 255);
}