body {
  font-family: "Pretendard";
}

.mainPageSlider {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(24,24,24,1);
}

.mainPageSlider {
  .mainPageSliderDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.mainPageSlider {
  .mainPageSliderDetail {
    .mainImage {
      display: flex;

      width: 1320px;
      height: 500px;
    }
  }
}

