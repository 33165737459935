body {
  font-family: 'Pretendard';
  user-select: none;
}

.mainPageHeader {
  display: flex;
  flex-direction: column;
}

.mainPageHeader {
  .mainPageDetail {
    display: flex;
    flex-direction: row;
    color: rgba(250,250,250,1);

    min-height: 50px;
    background-color: rgba(24,24,24,1);
  }
}

.mainPageHeader {
  .mainPageDetail {
    .mainPageLogoText {
      display: flex;
      align-items: center;

      margin-left: 30px;
    }
  }
}

.mainPageHeader {
  .mainPageDetail {
    .mainPageLogIn {
      display: flex;
      flex-direction: column;

      justify-content: center;
      margin-left: auto;
      margin-right: 30px;
    }
  }
}

.mainPageHeader {
  .mainPageDetail {
    .mainPageLogIn {
      .mainPageLoginText {
        display: flex;

        background-color: black;
        padding: 5px;
        border-radius: 5px;
      }

      .lockIcon {
        margin-right: 5px;
      }
    }
  }
}