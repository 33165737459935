.banpickMain {
	background-color: #090909;
	width: 1320px;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;	
}

.banpickMain .banpickselect {
	margin-top: 60px;
	margin-left: auto;
	margin-right: auto;	
	width: 1120px;
	height: 40px;
	background-color: #1c1c1c;
	color: white;
	display: flex;	
	border: 2px solid #1c1c1c;
}
.banpickselect .banpickmenumain{
	display: flex;
	justify-content: center;
	align-items: center;	
	/* height: 60px; */
	text-align: center;
	
}
.banpickselect .banpickmenumain:hover {
	background-color: rgba(10,10,10,1);
	transition: all 0.3s;
}
.banpickselect .banpickmenumain .banpickmenu{
	display: flex;
	justify-content: center;
	align-items: center;	
	float: left;
	width: 200px;
	/* height: 60px; */
	text-align: center;
	
}
.banpickselect .bar{
	display: flex;
	height: 70%;
	margin-top:6px;
	align-items: center;
	justify-content: center;
	width:2px;
	background-color: rgba(250,250,250,0.5);
}
.banpickselect .prefselectmain{
	display: flex;
	justify-content: center;
	align-items: center;	
	/* height: 60px; */
	text-align: center;
	
}
.banpickselect .prefselectmain:hover {
	background-color: rgba(10,10,10,1);
	transition: all 0.3s;
}
.banpickselect .prefselectmain .prefselect{
	display: flex;
	justify-content: center;
	align-items: center;	
	float: left;
	width: 200px;
	/* height: 60px; */
	text-align: center;
}
.banpickselect .share{
	display: flex;
	justify-content: center;
	align-items: center;	
	// float: left;
	width: 200px;
	/* height: 60px; */
	text-align: center;
	text-decoration: none;
	color: white;
}
.banpickselect .share:hover{
	background-color: rgba(10,10,10,1);
	transition: all 0.3s;
}

.sharepopup {
	display: none;
	justify-content: center;
	align-items: center;
}

.sharepopup .sharepopupcont{
	display: flex;
	flex-direction: row;
	width: 1120px;
	height: 260px;
	background-color: white;
}
.sharepopup .sharepopupcont .socialbrand{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
}

.banpickbox{
	display: flex;
	flex-direction: column;
	border: 2px solid #212121;
	width: 1120px;
	height: 260px;
	margin-left: auto;
	margin-right: auto;		
	text-align: center;
	margin-bottom: 60px;
	background-color: #090909;
}
.banpickbox .snip .banbox {
	display: flex;
	flex-direction: row;
}
.banpickbox .snip .banbox .banblue{
	display: flex;
	flex-direction: row;
	margin-left: 20px;
}
.banpickbox .snip .banbox .banred{
	display: flex;
	flex-direction: row;
	margin-left: auto;
	margin-right: 20px;
}
.banpickbox .snip .banbox .banred .ban{
	margin-top: 20px;
	margin-left: 7px;
	margin-right: 7px;	
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #181818;
	width: 50px;
	height: 50px;
}
.banpickbox .snip .banbox .banblue .ban{
	margin-top: 20px;
	margin-left: 7px;
	margin-right: 7px;	
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #181818;
	width: 50px;
	height: 50px;
}
.banpickbox .snip .banbox .banblue .blue{
	margin-top: 20px;
	margin-left: 13px;
	margin-right: 15px;	
	background-color: #053482;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	height: 50px;
	color: #ffffff;
	font-size: 15pt;
	font-weight: bold;
}
.banpickbox .snip .banbox .banred .red{
	margin-top: 20px;
	margin-left: 15px;
	margin-right: 13px;	
	background-color: #aa2b43;
	display: flex;
	align-items: center;
	justify-content: center;	
	width: 80px;
	height: 50px;
	color: #ffffff;
	font-size: 15pt;
	font-weight: bold;
}

.banpickbox .snip .pickbox {
	margin-top: 3px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;		
}

.banpickbox .snip .pickbox .bluepick {
	display: flex;
	// flex-direction: row;
}

.banpickbox .snip .pickbox .redpick {
	display: flex;
	// flex-direction: row-reverse;
}

.banpickbox .snip .pickbox .bluepick .pick{
	margin-top: 15px;
	margin-left: 5px;
	margin-right: 5px;	
	background-color: #181818;
	width: 73px;
	height: 133px;
}

.banpickbox .snip .pickbox .redpick .pick{
	margin-top: 15px;
	margin-left: 5px;
	margin-right: 5px;	
	background-color: #181818;
	width: 73px;
	height: 133px;
}

.banpickbox .snip .pickbox .playback{
	display: inline-flex;
	flex-direction: row;
	width: 244px;
	justify-content: center;
	margin-top: auto;		
}
.banpickbox .snip .pickbox .playback .play{
	margin-top: 15px;
	margin-left: 15px;
	margin-right: 15px;	
	background-color: #181818;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;	
}
.banpickbox .snip .pickbox .playback .play .i{
	color: rgba(250,250,250,1);
}

.banpickbox .snip .pickbox .playback .back{
	margin-top: 15px;
	margin-left: 15px;
	margin-right: 15px;	
	background-color: #181818;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;	
}
.banpickbox .snip .pickbox .playback .back .i{
	color: rgba(250,250,250,1);
}


.recommend {
	background-color: #181818;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;	
	width: 550px;
	/* height: 100px;	 */
}

.recommend .recom-elem {
	display: flex;
	background-color: #000000;
	margin-top: 20px;
	margin-left: 20px;
	margin-bottom: 20px;
	width: 80px;
	height: 80px;	
	display:inline-block;
}

.searchchar {
	display: flex;
	background-color: #181818;
	margin-top: 30px;
	margin-left: 50px;
	// margin-right: 30px;	
	width: 1120px;
	// height: 500px;	
}
.searchchar .search {
	display: flex;
	// align-items: center;
	// justify-content: center;	
	background-color: #090909;
	flex-direction: column;
}
.searchchar .search .locseach {
	display: flex;
	align-items: center;
	justify-content: center;
}

.searchchar .search .locseach .i{
	margin-left: 5px;
	color: rgba(250,250,250,1);
}

.searchchar .search .champsearched {
	display: flex;	
	align-items: center;
	justify-content: center;	
	margin-left: auto;	
	margin-right: auto;	
	flex-direction: row;
		
}
.searchchar .search .champsearched .searched {
	display: flex;	
	flex-direction: column;
	width: 70px;
	color: white;
	float: left;
	margin: 0px 0px 15px 15px;
}
.searchchar .search .champsearched .searched span {
	font-size: 8pt;
	text-align: center;
}
ul {
    list-style-type: none;
}
