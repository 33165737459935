body {
  font-family: 'Pretendard';
}

.mainPageWarning {
  display: flex;
  flex-direction: column;
}

.mainPageWarning {
  .mainPageWarningDetail {
    display: flex;
    flex-direction: row;

    padding: 5px;
    min-width: calc(100vw - 10px);
    background-color: rgba(239, 35, 60, 1);
  }
}

.mainPageWarning {
  .mainPageWarningDetail {
    .iconWarning {
      display: flex;
      color: rgba(250,250,250,1);
      margin-left: 30px;
      margin-right: 10px;
    }

    .mainPageWarningText {
      display: flex;
      flex-direction: row;

      color: rgba(250,250,250,1);
      font-weight: 700;
      font-size: 12pt;
    }

    .mainPageCheck {
      display: flex;
      
      margin-left: 10px;
      color: rgba(250,250,250,1);
      font-size: 10pt;

      border: 1px solid rgba(250,250,250,1);
      border-radius: 5px;
      padding: 2px 10px;
    }
  }
}