@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

body {
  margin: 0;
  background-color: rgba(24,24,24,1);
}

body * {
  font-family: 'Pretendard';
}

.mainPageBanPick {
  display: flex;
  flex-direction: column;
}